<template>
  <div>
    <div class="evaluate-submit">
      <div class="box">
        <p>放映时间</p>
        <p>{{ evaluate.date }}</p>
      </div>
      <div class="box">
        <p>放映地址</p>
        <p>{{ evaluate.showPoint.address }}</p>
      </div>
      <div v-if="evaluate.films.length > 0" class="box">
        <p>影片名称</p>
        <p>{{ evaluate.films.map(item => item.name).join('、') }}</p>
      </div>
      <div class="box">
        <p>您觉得本场放映的影片如何？</p>
        <div>
          <ul class="scoreBox">
            <li
              :class="{ active: evaluate.filmScore === 5 }"
              @click="evaluate.filmScore = 5"
            >
              <img
                v-if="evaluate.filmScore === 5"
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709ce5f576e4.png"
                alt=""
              />
              <img
                v-else
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709ce9694917.png"
                alt=""
              />
              <span>好看</span>
            </li>
            <li
              :class="{ active: evaluate.filmScore === 3 }"
              @click="evaluate.filmScore = 3"
            >
              <img
                v-if="evaluate.filmScore === 3"
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cec035356.png"
                alt=""
              />
              <img
                v-else
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cf00df66e.png"
                alt=""
              />
              <span>一般</span>
            </li>
            <li
              :class="{ active: evaluate.filmScore === 1 }"
              @click="evaluate.filmScore = 1"
            >
              <img
                v-if="evaluate.filmScore === 1"
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cf21368dc.png"
                alt=""
              />
              <img
                v-else
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cf409eae0.png"
                alt=""
              />
              不好看
            </li>
          </ul>
        </div>
        <hr />
        <p style="font-weight: bold">您对本场放映的服务态度是否满意？</p>
        <div>
          <ul class="scoreBox">
            <li
              :class="{ active: evaluate.serviceScore === 5 }"
              @click="evaluate.serviceScore = 5"
            >
              <img
                v-if="evaluate.serviceScore === 5"
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709ce5f576e4.png"
                alt=""
              />
              <img
                v-else
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709ce9694917.png"
                alt=""
              />
              <span>满意</span>
            </li>
            <li
              :class="{ active: evaluate.serviceScore === 3 }"
              @click="evaluate.serviceScore = 3"
            >
              <img
                v-if="evaluate.serviceScore === 3"
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cec035356.png"
                alt=""
              />
              <img
                v-else
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cf00df66e.png"
                alt=""
              />
              <span>一般</span>
            </li>
            <li
              :class="{ active: evaluate.serviceScore === 1 }"
              @click="evaluate.serviceScore = 1"
            >
              <img
                v-if="evaluate.serviceScore === 1"
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cf21368dc.png"
                alt=""
              />
              <img
                v-else
                src="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cf409eae0.png"
                alt=""
              />
              不满意
            </li>
          </ul>
        </div>
        <!--        {{evaluate}}-->
      </div>
    </div>
    <div class="box-button">
      <van-button
        round
        type="info"
        @click="submit"
        size="large"
        color="#3d8bff"
        style="width: 80%; height: 40px"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { api } from '@api'
import { SetPvAndUv } from '@/mixins/PvAndUv'

export default {
  name: 'evaluateSubmit',
  mixins: [SetPvAndUv],
  data() {
    return {
      evaluate: {
        taskId: '',
        date: '',
        films:[],
        showPoint: '',
        filmScore: 5,
        serviceScore: 5,
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$route.query)
      this.evaluate.taskId = this.$route.query.id
      this.evaluate.date = this.$route.query.playDatetime
      this.evaluate.showPoint = this.$route.query.showPoint
      this.evaluate.films = JSON.parse(this.$route.query.films)
      console.log(this.evaluate);

    })
  },
  methods: {
    submit() {
      const data = {
        planId: this.evaluate.taskId,
        filmEvaluate: this.evaluate.filmScore,
        serveEvaluate: this.evaluate.serviceScore,
      }
      api.submitFilmEvaluation(data).then((res) => {
        if (res.code == 200) {
          Dialog.alert({
            message: '提交成功',
          }).then(() => {
            this.$router.history.go(-1)
          })
        } else {
          Toast({
            message: '提交失败',
            duration: 1500,
          })
        }
      })
      // Toast(`提交成功`);
    },
    // 保留小数点后2位
  },
}
</script>

<style scoped lang="less">
.evaluate-submit {
  background-color: #f6f7f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  //padding-bottom: 70px;
  font-size: 14px;
  color: #333333;
  div {
    > p {
      &:first-child {
        font-weight: bold;
      }
    }
    > hr {
      width: 343px;
      border: none;
      height: 1px;
      background: #e8e9ec;
    }
  }

  > .box {
    margin-top: 10px;
    background: #ffffff;
    padding: 14px;
  }
}
.scoreBox {
  //border: 1px solid #E8E9EC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 90px;
  & > li {
    flex: 1;
    //border:1px solid red;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.box-button {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  //left: 10px;
}
</style>
